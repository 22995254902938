import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/MainLayout";
import { MediaGallery } from "../components/common/MediaGallery";
import { getAllMedia } from "../components/common/MediaGallery/util/tagging";
import { SEO } from "wmk-lib";
import { siteTitle, subdomainUrl, twitter } from "../vars/helpers";

const PhotosVideos = () => {
  const { videos, photos, vimeo } = useStaticQuery(query);
  const media = getAllMedia(videos, photos, vimeo);
  //console.log(vimeo,media)

  return (
    <Layout>
      <SEO
        baseUrl={subdomainUrl}
        title={"Photos & Videos"}
        siteTitle={siteTitle}
        slug="photos-videos"
        twitterHandle={twitter}
        twitterImage={null}
        ogImage={null}
      />
      <MediaGallery
        // photos={photosByCategory}
        // videos={videosByCategory}
        media={media}
        // width={vidImgTileWidth}
        // height={vidImgTileHeight}
      />
    </Layout>
  );
};

export default PhotosVideos;

const query = graphql`
  {
    photos: allFlickrPhoto {
      edges {
        node {
          ...NodeFlickrFields
        }
      }
    }
    videos: allYoutubeVideo {
      edges {
        node {
          ...NodeYoutubeFields
        }
      }
    }
    vimeo: allVimeoVideo {
      edges {
        node {
          ...NodeVimeoFields
        }
      }
    }
  }
`;
